<template>
  <div :class="generalClass">
    <div
      class="row"
      :class="$store.getters['layout/getInnerWidth'] < 900 ? 'p-2' : ''"
    >
      <div :class="getTitleClass">
        <h6 class="mt-4 title-perso-infs">
          {{ $t('views.client.details.infos.groups.title') }}
        </h6>
      </div>
      <div :class="getBorderClass" class="border-bottom-title">
      </div>

      <div class="container-fluid">

          <customer-groups
              :class="'mt-2 ' + getMargins"
              :client="client"
              mode="update"
              @on:groups-list-updated="onGroupsListUpdated"
          />
      </div>
    </div>
  </div>
</template>
<script>
import {putClubClient} from "@api/doinsport/services/client/client.api";

export default {
  props: {
    generalClass: {
      type: String,
      default: '',
    },
    client: {
      type: Object,
      default: () => {
      }
    },
  },
  data: () => ({
    innerWidth: 1600,
  }),
  components: {
    CustomerGroups: () => import('../../new/Groups')
  },
  computed: {
    getMargins() {
      if (this.innerWidth < 1200) {
        return '';
      } else {
        return 'ml-3 mr-2';
      }
    },
    getCols() {
      if (this.innerWidth < 900) {
        return '';
      } else {
        return '6';
      }
    },
    getEmailCols() {
      return '';
    },
    getTitleClass() {
      if (this.innerWidth > 1592 && this.innerWidth < 1930) {
        return 'col-md-4'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1592) {
        return 'col-md-5'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-5'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-3'
      }


    },
    getBorderClass() {
      if (this.innerWidth > 1592 && this.innerWidth < 1930) {
        return 'col-md-8'
      } else if (this.innerWidth > 1485 && this.innerWidth < 1592) {
        return 'col-md-7'
      } else if (this.innerWidth > 1200 && this.innerWidth < 1485) {
        return 'col-md-7'
      } else if (this.innerWidth > 700 && this.innerWidth < 1200) {
        return 'col-md-12'
      } else {
        return 'col-md-9'
      }

    }
  },
  methods: {
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    onGroupsListUpdated(group) {
      putClubClient({id: this.client.id, groups: this.client.groups})
          .then(
              (response) => {
              }
          );
    },
  },
  created() {
    this.innerWidth = window.innerWidth;
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  }
}
</script>
<style scoped>
.title-perso-infs {
  font: normal normal bold 21px/13px Avenir;
  letter-spacing: 0;
  color: #4D4F5C;
  opacity: 1;
}

@media only screen and (max-width: 1527px) {
  .title-perso-infs {
    font: normal normal bold 18px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

@media only screen and (max-width: 791px) {
  .title-perso-infs {
    font: normal normal bold 10px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

@media only screen and (max-width: 991px) {
  .title-perso-infs {
    font: normal normal bold 14px Avenir;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }
}

.border-bottom-title {
  border-bottom: 2px solid #EDF0F3;
}

.right-general-infs {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 0.5;
}

.left-general-infs {
  text-align: left;
  font: normal normal 15px Avenir;
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 1;
}

</style>
>
