<template>
  <b-card>
    <template v-if="client">
      <profile
        :client="client"
        :subscriptionCards="subscriptionCards"
        @on:reload-client="$emit('on:reload-client')"
      />
      <personal-informations
        :general-class="'mt-2'"
        :client="client"
      />
      <sub-details
        :general-class="'mt-2'"
        :client="client"
      />
      <family
        :general-class="'mt-2'"
        :client="client"
        @on:reload-client="$emit('on:reload-client')"
      />
      <groups
        :general-class="'mt-2'"
        :client="client"
        @on:reload-client="$emit('on:reload-client')"
      />
      <documents
        :general-class="'mt-2'"
        :client="client"
        @on:reload-client="$emit('on:reload-client')"
      />
      <div>
        <slot name="headerAction">
        </slot>
      </div>
    </template>
  </b-card>
</template>
<script>

import Groups from "@views/client/details/infos/Groups";

export default {
  components: {
    Groups,
    Profile: () => import('./Profile'),
    PersonalInformations: () => import('./PersonalInformations'),
    SubDetails: () => import('./SubDetails'),
    Family: () => import('./Family'),
    Documents: () => import('./Documents'),
  },
  props: {
    client: {
      type: Object,
      default: () => {
      },
    },
    subscriptionCards: {
      type: Array,
      default: () => [],
    },
  },
  created() {
  }
}
</script>
<style lang="scss" scoped>
@import "@lazy/_b-card.scss";
</style>
